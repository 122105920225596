import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

docReady(() => {
  const intro = document.getElementById('intro')

  if (intro) {
    const tl = gsap.timeline()
    const cine = intro.querySelector('.intro__cine')
    const deli = intro.querySelector('.intro__deli')
    const tagline = intro.querySelector('.intro__tagline')

    tl.to([cine, deli, tagline], {
      duration: 1,
      ease: 'expo.out',
      stagger: 0.2,
      y: 0,
      opacity: 1
    })

    tl.addLabel('afterIn')

    tl.to([cine, deli, tagline], {
      duration: 1,
      ease: 'expo.in',
      delay: 0.3,
      stagger: 0.1,
      y: '-100%',
      opacity: 0
    }, 'afterIn')

    tl.to(intro, {
      duration: 1,
      delay: 0.3,
      ease: 'expo.in',
      opacity: 0,
      onComplete () {
        intro.remove()
      }
    }, 'afterIn+=.8')
  }
})
