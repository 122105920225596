import docReady from '@/js/helpers/doc-ready'
import { createCookie, readCookie } from '@/js/helpers/cookie'

class FaqAccordion {
  constructor (el) {
    this.el = el
    this.initEvents()
  }

  clickHandler () {
    this.el.classList.toggle('active')
  }

  initEvents () {
    this.el.addEventListener('click', this.clickHandler.bind(this))
  }
}

class FormAccordion {
  constructor (el) {
    this.el = el
    this.attr = el.getAttribute('data-films-button')
    this.accordionEl = document.querySelector(`[data-films-accordion="${this.attr}"]`)
    this.active = false
    this.urlString = ''

    if (!readCookie(this.attr + '-active')) {
      createCookie(this.attr + '-active', this.active, 1)
    }

    this.initEvents()
  }

  clickHandler () {
    this.active = !this.active
    this.toggleAccordion()
  }

  toggleAccordion () {
    this.el.classList.toggle('active', this.active)
    this.accordionEl.classList.toggle('active', this.active)
    createCookie(this.attr + '-active', this.active, 1)
  }

  checkUrl () {
    this.urlString = window.location.search ? window.location.search.substring(1) : ''
  }

  initEvents () {
    this.active = readCookie(this.attr + '-active') === 'true'
    this.el.addEventListener('click', this.clickHandler.bind(this))
    // this.checkUrl()

    if (this.urlString.includes(this.attr)) {
      // createCookie(this.attr + '-active', this.active, 1)
    }
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-films-button]')).forEach(el => {
    // eslint-disable-next-line
    const accordion = new FormAccordion(el)
  })

  Array.from(document.querySelectorAll('[data-accordion-head]')).forEach(el => {
    // eslint-disable-next-line
    const accordion = new FaqAccordion(el)
  })
})
