import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'
import { getParameterByName, updateUrlParameter } from '@/js/helpers/url'

const axios = require('axios')
let isLoading = false

const getPost = (params) => {
  axios
    .get(window.ajaxurl, {
      params
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        const ajaxContainer = document.getElementById('post-container')

        ajaxContainer.insertAdjacentHTML('beforeEnd', data)

        const tl = gsap.timeline()

        tl.set(document.body, {
          overflow: 'hidden'
        })

        tl.to(ajaxContainer, {
          duration: 0.6,
          ease: 'expo.out',
          visibility: 'visible',
          opacity: '1',
          y: 0
        })

        const closeButton = ajaxContainer.querySelector('[data-close]')
        if (closeButton) {
          const closeHandler = e => {
            e.preventDefault()
            const buttonTl = gsap.timeline()
            buttonTl.to(ajaxContainer, {
              duration: 0.6,
              ease: 'expo.inOut',
              opacity: '0',
              y: '-50px'
            })

            buttonTl.set(ajaxContainer, {
              visibility: 'hidden'
            })

            buttonTl.set(document.body, {
              clearProps: 'overflow'
            })

            // empty div
            while (ajaxContainer.firstChild) {
              ajaxContainer.removeChild(ajaxContainer.firstChild)
            }

            window.history.pushState({}, '', window.location.origin + window.location.pathname)

            closeButton.removeEventListener('click', closeHandler)
          }
          closeButton.addEventListener('click', closeHandler)
        }

        // checkImages()
        const url = updateUrlParameter(window.location.href, 'post', params.postId)
        window.history.pushState({}, '', url)

        isLoading = false
      }
    })
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-post-id]')).forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault()

      if (isLoading) {
        return
      }

      isLoading = true

      const postId = el.getAttribute('data-post-id')

      getPost({
        action: 'load_post',
        postId
      })
    })
  })

  const paramPostId = getParameterByName('post')
  if (paramPostId) {
    isLoading = true

    getPost({
      action: 'load_post',
      postId: paramPostId
    })
  }

  window.addEventListener('popstate', function (event) {
    isLoading = true
    const paramPostId = getParameterByName('post')
    if (paramPostId) {
      getPost({
        action: 'load_post',
        postId: paramPostId
      })
    } else {
      isLoading = false
      const buttonTl = gsap.timeline()
      const ajaxContainer = document.getElementById('post-container')

      if (ajaxContainer && ajaxContainer.innerHTML !== '') {
        buttonTl.to(ajaxContainer, {
          duration: 0.6,
          ease: 'expo.inOut',
          opacity: '0',
          y: '-50px'
        })

        buttonTl.set(ajaxContainer, {
          visibility: 'hidden'
        })

        buttonTl.set(document.body, {
          clearProps: 'overflow'
        })

        // empty div
        while (ajaxContainer.firstChild) {
          ajaxContainer.removeChild(ajaxContainer.firstChild)
        }

        window.history.pushState({}, '', window.location.origin + window.location.pathname)
      }
    }
  })
})
