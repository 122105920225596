import Flickity from 'flickity'
import gsap from 'gsap'
import throttle from '@/js/helpers/throttle'

const imagesLoaded = require('imagesloaded')

const createSlider = (el, params) => {
  const sliderEl = el.querySelector('[data-slider-slides]')
  const gotoButtons = el.querySelectorAll('[data-slider-go]')
  const prevButtons = el.querySelectorAll('[data-slider-button="prev"]')
  const nextButtons = el.querySelectorAll('[data-slider-button="next"]')
  const currentIndexEls = el.querySelectorAll('[data-slider-current]')
  const lastIndexEls = el.querySelectorAll('[data-slider-last]')
  const progress = el.querySelector('[data-slider-progress]')

  const goNext = () => {
    flkty.next()
  }

  const goPrev = () => {
    flkty.previous()
  }

  const setHeight = button => {
    const filmCard = document.querySelector('.card--film .card__media img')
    gsap.set(button, {
      height: filmCard.offsetHeight
    })
  }

  const select = e => {
    const index = e.target.getAttribute('data-slider-go')
    flkty.select(index - 1)
  }

  const flktyOptions = Object.assign({}, {
    freeScroll: false,
    contain: true,
    percentPosition: false,
    prevNextButtons: false,
    pageDots: false,
    pauseAutoPlayOnHover: false,
    on: {
      deactivate () {
        if (prevButtons.length) {
          for (let i = 0; i < prevButtons.length; i++) {
            prevButtons[i].removeEventListener('click', goPrev)
          }
        }

        if (gotoButtons.length) {
          for (let i = 0; i < gotoButtons.length; i++) {
            gotoButtons[i].removeEventListener('click', select)
          }
        }

        if (nextButtons.length) {
          for (let i = 0; i < nextButtons.length; i++) {
            nextButtons[i].removeEventListener('click', goNext)
          }
        }
      },
      select (index) {
        const lastIndex = sliderEl.querySelectorAll('[data-slider-cell]').length
        const slidesCount = flkty ? flkty.slides.length : lastIndex

        if (lastIndexEls.length) {
          for (let i = 0; i < lastIndexEls.length; i++) {
            lastIndexEls[i].innerHTML = slidesCount
          }

          if (currentIndexEls.length) {
            for (let i = 0; i < currentIndexEls.length; i++) {
              currentIndexEls[i].setAttribute('aria-label', `Last page index: ${slidesCount}`)
            }
          }
        }

        if (gotoButtons.length) {
          for (let i = 0; i < gotoButtons.length; i++) {
            const buttonIndex = gotoButtons[i].getAttribute('data-slider-go')
            gotoButtons[i].classList.toggle('pagination__item--active', buttonIndex - 1 === index)
          }
        }

        if (progress && slidesCount) {
          const percentage = ((index + 1) / +slidesCount) * 100
          if (percentage) {
            gsap.to(progress, {
              duration: 1,
              ease: 'expo.out',
              x: percentage + '%'
            })
          }
        }
      },
      ready () {
        if (prevButtons.length) {
          for (let i = 0; i < prevButtons.length; i++) {
            prevButtons[i].addEventListener('click', goPrev)
            setHeight(prevButtons[i])

            window.addEventListener('resize', throttle(() => {
              setHeight(prevButtons[i])
            }, 100))
          }
        }

        if (gotoButtons.length) {
          for (let i = 0; i < gotoButtons.length; i++) {
            gotoButtons[i].addEventListener('click', select)
          }
        }

        if (nextButtons.length) {
          for (let i = 0; i < nextButtons.length; i++) {
            nextButtons[i].addEventListener('click', goNext)
            setHeight(nextButtons[i])

            window.addEventListener('resize', throttle(() => {
              setHeight(nextButtons[i])
            }, 100))
          }
        }

        if (!params || !params.wrapAround) {
          if (prevButtons.length) {
            for (let i = 0; i < prevButtons.length; i++) {
              prevButtons[i].disabled = true
            }
          }
        }

        imagesLoaded(sliderEl, () => {
          flkty.resize()
        })
      }
    }
  }, params)

  const flkty = new Flickity(sliderEl, flktyOptions)

  flkty.on('change', index => {
    if (currentIndexEls.length) {
      for (let i = 0; i < currentIndexEls.length; i++) {
        currentIndexEls[i].innerHTML = index + 1
      }
    }

    if (!params || !params.wrapAround) {
      if (prevButtons.length) {
        for (let i = 0; i < prevButtons.length; i++) {
          prevButtons[i].disabled = index === 0
        }
      }

      if (nextButtons.length) {
        for (let i = 0; i < nextButtons.length; i++) {
          nextButtons[i].disabled = index === flkty.slides.length - 1
        }
      }
    }
  })
}

export default createSlider
