import docReady from '@/js/helpers/doc-ready'
import { createCookie, readCookie } from '@/js/helpers/cookie'

class ThemeToggler {
  constructor () {
    this.el = document.querySelector('[data-toggle]')
    this.status = document.querySelector('[data-toggle-state]')

    this.active = false

    this.initEvents()
  }

  toggle () {
    this.active = !this.active

    this.status.innerHTML = this.active ? 'Aan' : 'Uit'
    document.documentElement.classList.toggle('theater-mode-active', this.active)
    // window.sessionStorage.setItem('theater-mode-active', this.active)
    createCookie('theater-mode-active', this.active, 1)
  }

  initEvents () {
    this.el.addEventListener('click', this.toggle.bind(this))

    // const mode = window.sessionStorage.getItem('theater-mode-active')
    const mode = readCookie('theater-mode-active')
    if (mode && mode === 'true') {
      this.toggle()
    }
  }
}

docReady(() => {
  const toggler = document.querySelector('[data-toggle]')

  if (toggler) {
    // eslint-disable-next-line
    const themeToggler = new ThemeToggler()
  }
})
