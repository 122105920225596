import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const checkboxes = document.querySelectorAll('.filter-system__checkbox')
  const searchForm = document.getElementById('search-form')

  if (checkboxes.length && searchForm) {
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].addEventListener('change', function () {
        searchForm.submit()
      })
    }
  }
})
