import docReady from '@/js/helpers/doc-ready'
import { snazzy } from '@/js/helpers/snazzy'

class Map {
  constructor (el) {
    this.el = el
    this.markers = el.querySelectorAll('[data-map-marker]')
    this.args = {
      zoom: 16,
      center: {
        lat: +this.markers[0].getAttribute('data-lat'),
        lng: +this.markers[0].getAttribute('data-lng')
      },
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      styles: snazzy
    }

    this.initMap()
  }

  initMap () {
    this.map = new window.google.maps.Map(this.el, this.args)
    this.map.markers = []

    for (let i = 0; i < this.markers.length; i++) {
      this.addMarker(this.markers[i])
    }
  }

  addMarker (marker) {
    const lat = marker.getAttribute('data-lat')
    const lng = marker.getAttribute('data-lng')
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    }

    const mapMarker = new window.google.maps.Marker({
      position: latLng,
      map: this.map
      // icon: `${window.templateurl}/dist/images/marker.svg`
    })

    this.map.markers.push(mapMarker)
  }
}

docReady(() => {
  const maps = document.querySelectorAll('[data-map]')
  if (maps && maps.length) {
    for (let i = 0; i < maps.length; i++) {
      // eslint-disable-next-line
      const map = new Map(maps[i])
    }
  }
})
