import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

docReady(() => {
  const piclEmbedButton = document.querySelector('[data-open-picl-embed]')
  const piclEmbed = document.querySelector('[data-picl-embed]')
  const piclClose = document.querySelector('[data-picl-close]')

  if (piclEmbedButton) {
    piclEmbedButton.addEventListener('click', () => {
      gsap.set(document.body, {
        overflow: 'hidden'
      })
      gsap.to(piclEmbed, {
        autoAlpha: 1
      })
    })
  }
  if (piclClose) {
    piclClose.addEventListener('click', () => {
      gsap.set(document.body, {
        clearProps: 'overflow'
      })
      gsap.to(piclEmbed, {
        autoAlpha: 0
      })
    })
  }
})
