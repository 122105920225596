import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const checkboxes = document.querySelectorAll('.filter-system__checkbox')
  const searchForm = document.getElementById('search-form')

  if (checkboxes.length && searchForm) {
    const params = {}
    const originalAction = searchForm.action ? searchForm.action : ''

    // const action = searchForm.action
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].addEventListener('change', function () {
        if (this.checked) {
          if (Object.prototype.hasOwnProperty.call(params, this.name)) {
            params[this.name].push(this.id)
          } else {
            params[this.name] = [this.id]
          }
        } else {
          if (params[this.name].length > 1) {
            const index = params[this.name].indexOf(this.id)
            if (index > -1) {
              params[this.name].splice(index, 1)
            }
          } else {
            delete params[this.name]
          }
        }

        const actionParams = []
        let paramsStr = '?'

        for (const key in params) {
          if (!Object.prototype.hasOwnProperty.call(params, key)) {
            continue
          }
          actionParams.push(`${key}=${params[key].join(',')}`)
        }

        if (actionParams.length) {
          paramsStr += actionParams.join('&')
        }

        searchForm.action = `${originalAction}${paramsStr}`
      })
    }
  }
})
