import Flickity from 'flickity'
import docReady from '@/js/helpers/doc-ready'
import { TweenMax, Power3 } from 'gsap'

docReady(() => {
  const sliderEl = document.querySelector('.hero__slides')
  const slides = document.querySelectorAll('.hero__slide')

  const loadMedia = index => {
    const currSlide = slides[index]
    if (currSlide) {
      const imageEl = currSlide.querySelector('.hero__image.lazy-slide-image.not-loaded')
      if (imageEl) {
        const src = imageEl.getAttribute('data-src')
        const srcset = imageEl.getAttribute('data-srcset')
        const sizes = imageEl.getAttribute('data-sizes')

        imageEl.onload = function () {
          imageEl.classList.remove('not-loaded')
          imageEl.classList.add('loaded')
        }

        imageEl.setAttribute('src', src)
        imageEl.setAttribute('srcset', srcset)
        imageEl.setAttribute('sizes', sizes)
      }

      const videoEl = currSlide.querySelector('.hero__video.lazy-video.not-loaded')
      if (videoEl) {
        videoEl.src = videoEl.getAttribute('data-src')
        videoEl.classList.remove('not-loaded')

        TweenMax.to(videoEl, 1, {
          opacity: 1,
          delay: 2,
          ease: Power3.easeOut
        })
      }
    }
  }

  setTimeout(() => {
    if (sliderEl) {
      // eslint-disable-next-line
      const flkty = new Flickity(sliderEl, {
        autoPlay: 4000, // 4000
        freeScroll: false,
        wrapAround: true,
        percentPosition: true,
        prevNextButtons: false,
        pageDots: false,
        // fade: true,
        pauseAutoPlayOnHover: false,
        on: {
          ready () {
            loadMedia(0)
            loadMedia(1)
          },
          settle (index) {
            loadMedia(index)
            loadMedia(index + 1)
          }
        }
      })
    }
  }, 1000)
})
