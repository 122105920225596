import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.category-section__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: false,
      groupCells: true,
      cellAlign: 'left'
    })
  })

  Array.from(document.querySelectorAll('.post-gallery')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      groupCells: false,
      cellAlign: 'left'
    })
  })
})
